import {OrderType, Side} from "../../../assets/lib/charting_library"

export const defineOrderTypeAndSide = (mt5Type: number): { type: OrderType; side: Side, sideString: string } => {
    switch (mt5Type) {
        case 0: // BUY
            return {type: OrderType.Market, side: Side.Buy, sideString: "Buy"}
        case 1: // SELL
            return {type: OrderType.Market, side: Side.Sell, sideString: "Sell"}
        case 2: // BUY_LIMIT
            return {type: OrderType.Limit, side: Side.Buy, sideString: "Buy"}
        case 3: // SELL_LIMIT
            return {type: OrderType.Limit, side: Side.Sell, sideString: "Sell"}
        case 4: // BUY_STOP
            return {type: OrderType.Stop, side: Side.Buy, sideString: "Buy"}
        case 5: // SELL_STOP
            return {type: OrderType.Stop, side: Side.Sell, sideString: "Sell"}
        case 6: // BUY_STOP_LIMIT
            return {type: OrderType.StopLimit, side: Side.Buy, sideString: "Buy"}
        case 7: // SELL_STOP_LIMIT
            return {type: OrderType.StopLimit, side: Side.Sell, sideString: "Sell"}
        case 8: // CLOSE_BY
            return {type: OrderType.Market, side: Side.Buy, sideString: "Buy"}
        default:
            return {type: OrderType.Market, side: Side.Buy, sideString: "Buy"}
    }
}

export const defineTradeExecutionErrorType = (mt5Type: number): string => {
    switch (mt5Type) {
        case 0: // BUY
            return "Buy"
        case 1: // SELL
            return "Sell"
        case 2: // BUY_LIMIT
            return "Buy Limit"
        case 3: // SELL_LIMIT
            return "Sell Limit"
        case 4: // BUY_STOP
            return "Buy Stop"
        case 5: // SELL_STOP
            return "Sell Stop"
        case 6: // BUY_STOP_LIMIT
            return "Buy Stop Limit"
        case 7: // SELL_STOP_LIMIT
            return "Sell Stop Limit"
        case 8: // CLOSE_BY
            return "Close By"
        default:
            return "Unknown"
    }
}

export const defineFundOperationType = (mt5Type: number): string => {
    switch (mt5Type) {
        case 0:  // DEAL_BUY
            return "Buy Deal."
        case 1:  // DEAL_SELL
            return "Sell Deal."
        case 2:  // DEAL_BALANCE
            return "Balance Operation."
        case 3:  // DEAL_CREDIT
            return "Credit Operation."
        case 4:  // DEAL_CHARGE
            return "Additional Charges/Withdrawals."
        case 5:  // DEAL_CORRECTION
            return "Correction Operations."
        case 6:  // DEAL_BONUS
            return "Bonus Accrual. Issued bonuses increase the client's credit funds (IMTUser::Credit)."
        case 7:  // DEAL_COMMISSION
            return "Commissions."
        case 8:  // DEAL_COMMISSION_DAILY
            return "Daily Commissions."
        case 9:  // DEAL_COMMISSION_MONTHLY
            return "Monthly Commissions."
        case 10: // DEAL_AGENT_DAILY
            return "Daily Agent Commissions."
        case 11: // DEAL_AGENT_MONTHLY
            return "Monthly Agent Commissions."
        case 12: // DEAL_INTERESTRATE
            return "Annual Interest Accrual."
        case 13: // DEAL_BUY_CANCELED
            return "Cancelled Buy Deal. Using the trading execution IMTExecution::TE_DEAL_CANCEL, the Gateway API can notify the platform about the cancellation of a previously executed deal in an external trading system. In this case, the type of the previously executed buy deal in the platform changes to this type. The profit/loss of the deal is reset. After that, the client's positions are recalculated, and the corresponding profit/loss is credited/debited separately via a balance operation. The cancellation of the deal does not alter the client's order history. Deals of type DEAL_BUY_CANCELED do not participate in the calculation of the account's financial status and are not considered when recalculating positions."
        case 14: // DEAL_SELL_CANCELED
            return "Cancelled Sell Deal. Using the trading execution IMTExecution::TE_DEAL_CANCEL, the Gateway API can notify the platform about the cancellation of a previously executed deal in an external trading system. In this case, the type of the previously executed sell deal in the platform changes to this type. The profit/loss of the deal is reset. After that, the client's positions are recalculated, and the corresponding profit/loss is credited/debited separately via a balance operation. The cancellation of the deal does not alter the client's order history. Deals of type DEAL_SELL_CANCELED do not participate in the calculation of the account's financial status and are not considered when recalculating positions."
        case 15: // DEAL_DIVIDEND
            return "Dividend Accrual."
        case 16: // DEAL_DIVIDEND_FRANKED
            return "Franked Dividend Accrual (tax-exempt, the company covers the tax for the client)."
        case 17: // DEAL_TAX
            return "Tax Withholding."
        case 18: // DEAL_AGENT
            return "Agent Commission Accrual. Used for immediate commission accrual to the agent (each time their client executes a deal)."
        case 19: // DEAL_SO_COMPENSATION
            return "Operation Related to Negative Balance Compensation After Stop Out (IMTConGroup::TRADEFLAGS_SO_COMPENSATION)."
        case 20: // DEAL_SO_COMPENSATION_CREDIT
            return "Credit Funds Write-Off After Negative Balance Compensation (IMTConGroup::TRADEFLAGS_SO_COMPENSATION_CREDIT)."
        default: // Unknown deal type
            return "End"
    }
}