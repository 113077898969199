const business = [
    "#F5F3FF", //50,
    "#EDE9FE", //100,
    "inherit", //150,
    "#DDD6FF", //200,
    "inherit", //250,
    "#C4B4FF", //300,
    "inherit", //350,
    "#A684FF", //400,
    "inherit", //450,
    "#8E51FF", //500,
    "inherit", //550,
    "#7F22FE", //600,
    "inherit", //650,
    "#7008E7", //700,
    "inherit", //750,
    "#5D0EC0", //800,
    "inherit", //850,
    "#4D179A", //900,
    "#2F0D68", //950,
]

const grayLight = [
    "#F4F4F5", //50,  //light dom buy sell border
    "#F4F4F5", //100, //light list first (internal) borders + hover list
    "#E4E4E7", //150, //light second borders main
    "#D4D4D8", //200, //light border / border take profit
    "inherit", //250,
    "#D4D4D8", //300, //border hover settings checkbox
    "#D4D4D8", //350, //light input search placeholder search symbol
    "#9F9FA9", //400, //light market close color text
    "inherit", //450,
    "#71717B", //500, //light list head (setting list left bar options)
    "#71717B", //550, //light color of table headers text
    "#71717B", //600, //light border color in tooltip
    "inherit", //650,
    "inherit", //700,
    "inherit", //750,
    "#52525C", //800, //light tooltip bg
    "inherit", //850,
    "#18181B", //900, //light color text
    "#09090B", //950,
]

const grayDark = [
    "inherit", //50,
    "#D4D4D8", //100,
    "inherit", //150,
    "#D4D4D8", //200, //dark text color
    "#D4D4D8", //250, //dark hover text in list options settings
    "#9F9FA9", //300, //dark black color of number in chart
    "#D4D4D8", //350, //dark input search text
    "#9F9FA9", //400, //dark line in chart hover
    "#9F9FA9", //450, //dark color of table headers text
    "#9F9FA9", //500, //dark title text in list options settings
    "#9F9FA9", //550, //dark background border hover
    "#9F9FA9", //600, //dark market close color
    "#3F3F46", //650, //dark sell buy btn color + border take profit + borders color
    "#3F3F46", //700, //dark secondary borders on table and delimiter
    "#27272A", //750, //dark secondary borders on widget list + hover tabs in search
    "#27272A", //800, //dark main borders and list hover
    "#18181B", //850, //dark bg popup
    "#09090B", //900, //dark bg color
    "#09090B", //950, //dark bg hover for modal
]

const red = [
    "#fef2f2", //50,
    "#ffe2e2", //100,
    "inherit", //150,
    "#ffc9c9", //200,
    "inherit", //250,
    "#ffa2a2", //300,
    "inherit", //350,
    "#ff6467", //400,
    "inherit", //450,
    "#fb2c36", //500,
    "inherit", //550,
    "#e7000b", //600,
    "inherit", //650,
    "#c10007", //700,
    "inherit", //750,
    "#9f0712", //800,
    "inherit", //850,
    "#82181a", //900,
    "#460809" //950,
]

const green = [
    "#ecfdf5", //50,
    "#d0fae5", //100,
    "inherit", //150,
    "#a4f4cf", //200,
    "inherit", //250,
    "#5ee9b5", //300,
    "inherit", //350,
    "#00d492", //400,
    "inherit", //450,
    "#00bc7d", //500,
    "inherit", //550,
    "#009966", //600,
    "inherit", //650,
    "#007a55", //700,
    "inherit", //750,
    "#006045", //800,
    "inherit", //850,
    "#004f3b", //900,
    "#002c22" //950,
]

const blue = [
    "#eff6ff", //50,
    "#dbeafe", //100,
    "inherit", //150,
    "#bfdbfe", //200,
    "inherit", //250,
    "#93c5fd", //300,
    "inherit", //350,
    "#60a5fa", //400,
    "inherit", //450,
    "#3b82f6", //500,
    "inherit", //550,
    "#2563eb", //600,
    "inherit", //650,
    "#1d4ed8", //700,
    "inherit", //750,
    "#1e40af", //800,
    "inherit", //850,
    "#1e3a8a", //900,
    "#172554" //950,
]

const yellow = [
    "#fefce8", //50,
    "#fef9c3", //100,
    "inherit", //150,
    "#fef08a", //200,
    "inherit", //250,
    "#fde047", //300,
    "inherit", //350,
    "#facc15", //400,
    "inherit", //450,
    "#eab308", //500,
    "inherit", //550,
    "#ca8a04", //600,
    "inherit", //650,
    "#facc15", //700, //light star color
    "inherit", //750,
    "#eab308", //800, //light star color
    "inherit", //850,
    "#713f12", //900,
    "#422006" //950,
]

const orange = [
    "#fff7ed", //50,
    "#ffedd5", //100,
    "inherit", //150,
    "#fed7aa", //200,
    "inherit", //250,
    "#fdba74", //300,
    "inherit", //350,
    "#fb923c", //400,
    "inherit", //450,
    "#f97316", //500,
    "inherit", //550,
    "#ea580c", //600,
    "inherit", //650,
    "#c2410c", //700,
    "inherit", //750,
    "#9a3412", //800,
    "inherit", //850,
    "#7c2d12", //900,
    "#431407" //950,
]

export const TVColors = {
    business,
    grayLight,
    grayDark,
    red,
    green,
    blue,
    yellow,
    orange
}

export const light = {
    "color1": [
        ...business
    ],
    "color2": [
        ...grayLight
    ],
    "color3": [
        ...red
    ],
    "color4": [
        ...green
    ],
    "color5": [
        ...orange
    ],
    "color6": [
        ...blue
    ],
    "color7": [
        ...yellow
    ],
    white: "#ffffff",
    black: "inherit"
}

export const dark = {
    "color1": [
        ...business
    ],
    "color2": [
        ...grayDark
    ],
    "color3": [
        ...red
    ],
    "color4": [
        ...green
    ],
    "color5": [
        ...orange
    ],
    "color6": [
        ...blue
    ],
    "color7": [
        ...yellow
    ],
    white: "inherit",
    black: "inherit"
}









