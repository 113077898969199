export const formatPrice = (
    amount: number,
    digits: number,
    isUseMaxFractionDigits: boolean = false
) => {
    return Intl.NumberFormat(
        Intl.DateTimeFormat().resolvedOptions().locale,
        {
            ...(isUseMaxFractionDigits && {maximumFractionDigits: digits}),
            maximumFractionDigits: digits,
            roundingMode: "halfExpand"
        }
    ).format(amount)
}