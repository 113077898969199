import * as React from "react"
import {RefObject, useContext, useEffect, useRef, useState} from "react"
import {
    CustomThemes,
    IChartingLibraryWidget,
    ResolutionString,
    ThemeName,
    Timezone,
    TradingTerminalWidgetOptions,
    widget
} from "../../assets/lib/charting_library"
import {getLanguageFromURL} from "../../assets/utils/language"
import {Config} from "../../context/config"
import {DataFeed} from "./lib/DataFeed"
import {Auth} from "../../context/auth"
import {dark, light, TVColors} from "./utils/theme"
import {IBrokerConnectionAdapterHost} from "../../assets/lib/charting_library/broker-api"
import {Broker} from "./lib/Broker"
import {getThemeFromUrlOrStorage} from "../../assets/utils/theme"
import {useSleepCheckWorkerLoopEngine} from "./hooks/useSleepCheckWorkerLoopEngine"
import {SaveLoadAdapter} from "./SaveLoadAdapter"
import {SettingsAdapter} from "./SettingsAdapter"
import {RemoteSettingsManager, Setting} from "../../settings/RemoteSettingsManager"
import translate from "./utils/translate"
import {CalculatorWrapper} from "../calculatorWrapper"
import styles from "./Tradingview.module.scss"
import {SideBarWrapper} from "../sideBarWrapper"
import {NewsFeed} from "./lib/NewsFeed"
import {$fetch} from "../../assets/utils/fetch"
import {WebSocketsTicker} from "./websockets/WebSocketsTicker"
import {WebSocketsTradeEvents} from "./websockets/WebSocketsTradeEvents"
import {defineFundOperationType, defineOrderTypeAndSide} from "./utils/switchState"
import {formatPrice} from "../../assets/utils/format"
import {generatePdfTable} from "../../assets/utils/pdf"
import moment from "moment"

const CHART_RESOLUTION_KEY = "chartResolution"
const CURRENT_SYMBOL_KEY = "current_symbol"

export const TradingViewWidget = () => {
    const contextAuth = useContext(Auth)
    const config = useContext(Config)
    const chartContainerRef = useRef<HTMLDivElement>(null) as RefObject<HTMLInputElement | null>
    const getThemeFromStorage = () => (localStorage.getItem("theme") || "light") as ThemeName
    const [datafeedAPI, setDataFeedAPI] = useState<DataFeed | null>(null)
    const [renderCount, setRenderCount] = useState<number>(0)
    const [domCheckWorkerInstance, setDomCheckWorkerInstance] = useState<any>(null)
    const [theme, setTheme] = useState<ThemeName>(getThemeFromUrlOrStorage())
    const [isShowCalculator, setIsShowCalculator] = useState<boolean>(localStorage.getItem("isShowCalculator") === "true")

    const setIsShowCalculatorHandler = () => {
        localStorage.setItem("isShowCalculator", JSON.stringify(!isShowCalculator))
        setIsShowCalculator((prev) => !prev)
    }

    useSleepCheckWorkerLoopEngine(() => setRenderCount((prev) => prev + 1))

    useEffect(() => {
        WebSocketsTicker.getInstance(config, contextAuth)
        return () => WebSocketsTicker.getInstance(config, contextAuth).disconnect()
    }, [contextAuth.loginAccountId])

    useEffect(() => {
        WebSocketsTradeEvents.getInstance(config, contextAuth)
        return () => WebSocketsTradeEvents.getInstance(config, contextAuth).disconnect()
    }, [contextAuth.loginAccountId])

    useEffect(() => {
        DataFeed.removeInstance()
        const newInstance = DataFeed.getInstance(config, contextAuth)
        setDataFeedAPI(newInstance)
        return () => DataFeed.removeInstance()
    }, [contextAuth.loginAccountId])

    useEffect(() => {
        const worker = new Worker(new URL("../../assets/utils/workerLoopEngine.ts", import.meta.url), {type: "module"})
        worker.postMessage({command: "start", intervalMs: 1500})
        setDomCheckWorkerInstance(worker)
        return () => {
            worker.postMessage({command: "stop"})
            worker.terminate()
            setDomCheckWorkerInstance(null)
        }
    }, [])

    useEffect(() => {
        if (!datafeedAPI) return
        let widgetInstance: IChartingLibraryWidget

        const setUpDefaultWatchlist = async () => {
            const defaultList = SettingsAdapter.defaultAdapter().defaultSymbols
            if (defaultList) {
                const api = await widgetInstance.watchList()
                const listId = api.getActiveListId()
                listId && api.updateList(listId, defaultList)
            }
        }

        const enableTradingViewCasingBugCorrection = async () => {
            const api = await widgetInstance.watchList()
            api.onListAdded().subscribe(null, (listId: string, symbols: string[]) => {
                const lookupTable: Record<string, string> = {}
                for (const symbolName of datafeedAPI.getSymbolNames()) {
                    lookupTable[symbolName.toUpperCase()] = symbolName
                }
                const matchedSymbols: string[] = []
                for (const symbolName of symbols) {
                    if (symbolName.indexOf("###") === 0) {
                        matchedSymbols.push(symbolName)
                    } else {
                        const existentSymbol = lookupTable[symbolName.toUpperCase()]
                        existentSymbol && matchedSymbols.push(existentSymbol)
                    }
                }
                setTimeout(() => {
                    api.updateList(listId, matchedSymbols)
                }, 0)
            })
        }

        const initializeWidget = async () => {
            const symbols = await datafeedAPI.getSymbols()
            const allSymbols = await datafeedAPI.getAllSymbols()
            await SettingsAdapter.setUpDefaultAdapter(config, contextAuth)
            const initialSymbol = await getInitialSymbolName(symbols)
            datafeedAPI.setLastSelectedSymbolName(initialSymbol)
            const chartStateAdapter = await SaveLoadAdapter.adapterForLogin(contextAuth.loginAccountId || 0)
            const newsfeed = NewsFeed.getInstance(allSymbols, config)

            const widgetOptions: TradingTerminalWidgetOptions = {
                debug: false,
                container: chartContainerRef.current!,
                datafeed: datafeedAPI,
                interval: (localStorage.getItem(CHART_RESOLUTION_KEY) || "60") as ResolutionString,
                timezone: config.userTimezone as Timezone,
                symbol: initialSymbol,
                custom_css_url: "/styles/indexTradingView.css",
                library_path: "/charting_library/",
                locale: getLanguageFromURL(),
                autosize: true,
                load_last_chart: true,
                fullscreen: true,
                theme,
                enabled_features: [
                    "disable_resolution_rebuild",
                    "watchlist_sections",
                    "show_symbol_logos",
                    "show_exchange_logos",
                    "request_only_visible_range_on_reset",
                    "study_templates",
                    "tick_resolution",
                    "seconds_resolution",
                    "create_volume_indicator_by_default",
                    "dom_widget"
                ],
                disabled_features: [
                    "trading_notifications"
                ],
                widgetbar: {
                    details: true,
                    news: true,
                    watchlist: true,
                    datawindow: true,
                    watchlist_settings: {
                        default_symbols: [],
                        readonly: false
                    }
                },
                news_provider: newsfeed.newsProvider.bind(newsfeed),
                //@ts-ignore
                custom_translate_function: translate,
                //@ts-ignore
                broker_factory: (host: IBrokerConnectionAdapterHost) => {
                    Broker.setInstance(host, datafeedAPI, {
                            applicationConfiguration: config,
                            contextAuth,
                            symbols,
                            allSymbols,
                            webSocketsTradeEventsInstance: WebSocketsTradeEvents.getInstance(config, contextAuth)
                    })

                    return Broker.getInstance()
                },
                // debug_broker: "broker-only",
                broker_config: {
                    configFlags: {
                        supportOrdersHistory: true,
                        supportNativeReversePosition: true,
                        supportClosePosition: true,
                        supportPLUpdate: true,
                        supportLevel2Data: true,
                        showQuantityInsteadOfAmount: true,
                        supportEditAmount: false,
                        supportOrderBrackets: true,
                        supportMarketBrackets: true,
                        supportPositionBrackets: true,
                        showNotificationsLog: false,
                        supportPartialClosePosition: true,
                        supportPartialCloseIndividualPosition: true,
                        supportExecutions: true
                    },
                    durations: [
                        {name: "GTC", value: "GTC"},
                        {name: "DAY", value: "DAY"}
                    ]
                },
                custom_themes: {
                    light: light,
                    dark: dark
                } as CustomThemes,
                overrides: {
                    "scalesProperties.showBidAskLabels": true,
                    "mainSeriesProperties.bidAsk.visible": true,
                    "tradingProperties.showExecutions": true
                },
                studies_overrides: {
                    "volume.volume.color.0": TVColors.green[9],
                    "volume.volume.color.1": TVColors.red[9],
                    "volume.volume.transparency": 70
                },
                settings_adapter: SettingsAdapter.defaultAdapter(),
                save_load_adapter: chartStateAdapter
            }

            widgetInstance = new widget(widgetOptions)

            widgetInstance.onChartReady(async () => {
                domCheckWorkerInstance.onmessage = async () => {
                    await widgetReadyBuildCustomBrokerAPIPanel(widgetInstance, false)
                }
                widgetInstance.subscribe("activeChartChanged", (index) => {
                    const {name = ""} = widgetInstance.chart(index).symbolExt() || {}
                    datafeedAPI.setLastSelectedSymbolName(name)
                })
                widgetInstance.subscribe("series_properties_changed", () => {
                    const {name = ""} = widgetInstance.activeChart().symbolExt() || {}
                    datafeedAPI.setLastSelectedSymbolName(name)
                })
                const chart = widgetInstance.activeChart()
                chart.onSymbolChanged().subscribe(null, ((newSymbol: any) => {
                    saveInitialSymbolName(newSymbol.name)
                    datafeedAPI.setLastSelectedSymbolName(newSymbol.name)
                }) as (() => void))
                chart.onIntervalChanged().subscribe(null, (newResolution) => {
                    localStorage.setItem(CHART_RESOLUTION_KEY, newResolution)
                })
                widgetInstance.headerReady().then(() => {
                    widgetReadyCalculator(widgetInstance)
                    widgetReadyThemeToggle(widgetInstance)
                    widgetReadyLogout(widgetInstance)
                })
                await widgetReadyHandlerClickToAttachCustomBrokerAPIPanel({
                    widget: widgetInstance,
                    querySelectorValues: [".brokerButton-hw_3o_pb.brokerButtonDefault-hw_3o_pb"],
                    isHandledByMobileAction: true
                })
                await widgetReadyHandlerClickToAttachCustomBrokerAPIPanel({
                    widget: widgetInstance,
                    querySelectorValues: ["[data-name=\"paper_trading\"]", "[data-name=\"toggle-visibility-button\"]"],
                    isHandledByMobileAction: false
                })
                await widgetReadyBuildCustomBrokerAPIPanel(widgetInstance, false)
                await enableTradingViewCasingBugCorrection()
                await setUpDefaultWatchlist()
            })
        }

        initializeWidget()

        return () => {
            widgetInstance && widgetInstance.remove()
            Broker.removeInstance()
        }
    }, [datafeedAPI, renderCount])

    const widgetReadyHandlerClickToAttachCustomBrokerAPIPanel = async (
        {
            widget,
            querySelectorValues,
            isHandledByMobileAction
        }: {
            widget: any,
            querySelectorValues: string[],
            isHandledByMobileAction: boolean
        }) => {
        const doc = await waitForIFrameDocumentReady(widget)
        for (const querySelectorValue of querySelectorValues) {
            const btn = doc.querySelector(querySelectorValue) as any
            if (btn) {
                btn.onclick = function (e: any) {
                    e.preventDefault()
                    setTimeout(() => {
                        widgetReadyBuildCustomBrokerAPIPanel(widget, isHandledByMobileAction)
                    }, 100)
                }
            }
        }
    }

    const widgetReadyBuildCustomBrokerAPIPanel = async (widget: any, isHandledByMobileAction: boolean) => {
        const doc = await waitForIFrameDocumentReady(widget)
        const accountManagerElements = doc.querySelectorAll(`.js-account-manager-header`)

        const actionsBarElement = document.createElement("div")
        actionsBarElement.classList.add("customActionsBarManager")

        const closeALlPositionsElement = createCloseAllButton()
        const downloadAllHistoryElement = createDownloadAllHistoryButton()
        const depositElement = createDepositButton()

        accountManagerElements.forEach((el: any) => {
            if (el.parentNode.querySelector(".customActionsBarManager")) return
            if (!isHandledByMobileAction) {
                actionsBarElement.style.borderTop = "none"
                actionsBarElement.style.marginBottom = "8px"
            }
            actionsBarElement.appendChild(closeALlPositionsElement)
            actionsBarElement.appendChild(downloadAllHistoryElement)
            actionsBarElement.appendChild(depositElement)
            el.parentNode.insertBefore(actionsBarElement, el)
        })

        function createDownloadAllHistoryButton() {
            const el = document.createElement("button")
            el.textContent = "All History"
            el.className = "button-mXzF5cTO lightButton-bYDQcOkp secondary-PVWoXu5j gray-PVWoXu5j xsmall-bYDQcOkp"
            el.onclick = async () => {
                el.disabled = true
                const histories = []
                try {
                    let hasMore = true
                    let page = 1
                    while (hasMore) {
                        const response = await $fetch.get(`${config.urlRestAPI}/api/v1/history/${contextAuth.loginAccountId}/deals?page=${page}&page_size=${1000}`)
                        const {items, has_more} = await response.json()
                        histories.push(...items)
                        hasMore = has_more
                        page++
                    }
                } catch (error) {
                    console.error("Error download history:", error)
                } finally {
                    el.disabled = false
                }
                const [
                    deals,
                    fundsOperations
                ] = [
                    histories.filter((history) => history.symbol),
                    histories.filter((history) => !history.symbol)
                ]
                generatePdfTable("History", [
                    {
                        title: "Deals",
                        head: [["Account", "Symbol", "Deal", "Time", "Side", "Volume", "Price", "Stop Loss", "Take Profit", "Commission", "Swap", "Profit"]],
                        body: deals.reverse().map((history: any) => [history.login, history.symbol, history.ticket, moment(history.time).format("YYYY-MM-DD HH:mm:ss"), defineOrderTypeAndSide(history.action).sideString, history.volume_lots, formatPrice(history.price, 5), formatPrice(history.sl, 5), formatPrice(history.tp, 5), history.commission, history.swap.toFixed(2), formatPrice(history.profit, 2)])
                    },
                    {
                        title: "Funds Operations",
                        head: [["Account", "Deal", "Time", "Operation", "Comment", "Amount"]],
                        body: fundsOperations.reverse().map((history: any) => [history.login, history.ticket, moment(history.time).format("YYYY-MM-DD HH:mm:ss"), defineFundOperationType(history.action), history.comment.trim(), formatPrice(history.price, 2)])
                    }
                ])
            }
            return el
        }

        function createDepositButton() {
            const el = document.createElement("button")
            el.textContent = "Instant Top Up"
            el.className = "button-mXzF5cTO lightButton-bYDQcOkp secondary-PVWoXu5j gray-PVWoXu5j xsmall-bYDQcOkp"
            el.onclick = () => window.open(config.linkDeposit, "_blank")
            return el
        }

        function createCloseAllButton() {
            const el = document.createElement("button")
            el.textContent = "Close All Positions"
            el.className = "button-mXzF5cTO lightButton-bYDQcOkp secondary-PVWoXu5j gray-PVWoXu5j xsmall-bYDQcOkp"
            el.onclick = () => {
                widget.showConfirmDialog({
                    title: "Confirmation",
                    body: "Are you sure you want to close all positions?",
                    mainButtonText: "Yes",
                    cancelButtonText: "No",
                    callback: (isApproved: any) => {
                        if (isApproved) {
                            $fetch.delete(`${config.urlRestAPI}/api/v1/orders/${contextAuth.loginAccountId}/close-all`)
                        }
                    }
                })
            }
            return el
        }
    }

    const waitForIFrameDocumentReady = (widget: any): Promise<Document> => {
        return new Promise((resolve) => {
            const doc = widget._iFrame.contentWindow.document
            if (doc.readyState === "complete") resolve(doc)
            else {
                const onReadyStateChange = () => {
                    if (doc.readyState === "complete") {
                        doc.removeEventListener("readystatechange", onReadyStateChange)
                        resolve(doc)
                    }
                }
                doc.addEventListener("readystatechange", onReadyStateChange)
            }
        })
    }

    const widgetReadyThemeToggle = (widget: any) => {
        const theme = getThemeFromStorage()
        const el = widget.createButton({
            align: "right"
        })
        el.dataset.internalAllowKeyboardNavigation = "true"
        el.id = "theme-toggle"
        el.title = "Toggle Theme"
        el.innerHTML = `
            <div class="theme-switch-wrapper">
                <label for="theme-switch" id="theme-switch-label">Dark Mode</label>
                <div class="switchWrap-bl9AR3Gv">
                    <span class="switcher-fwE97QDf">
                        <input id="theme-switch" tabindex="-1" type="checkbox" class="input-fwE97QDf activeStylesEnabled-fwE97QDf">
                        <span class="thumbWrapper-fwE97QDf">
                            <span class="switchView-CtnpmPzP small-CtnpmPzP">
                                <span class="track-CtnpmPzP"></span>
                                <span class="thumb-CtnpmPzP"></span>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        `
        const checkboxEl = el.querySelector("#theme-switch")
        const switchViewEl = el.querySelector(".switchView-CtnpmPzP")

        checkboxEl.checked = theme === "dark"
        if (theme === "dark") switchViewEl.classList.add("checked-CtnpmPzP")

        checkboxEl.addEventListener("change", function () {
            const theme = getThemeFromStorage()
            const themeToSet = theme === "dark" ? "light" : "dark"
            localStorage.setItem("theme", themeToSet)
            document.documentElement.setAttribute("data-bs-theme", themeToSet)
            themeToSet === "dark"
                ? switchViewEl.classList.add("checked-CtnpmPzP")
                : switchViewEl.classList.remove("checked-CtnpmPzP")
            setTheme(themeToSet)
            widget.changeTheme(themeToSet, {disableUndo: false})
        })
    }

    const widgetReadyLogout = (widgetInstance: any) => {
        const logoutButton = widgetInstance.createButton({
            align: "right"
        })
        logoutButton.setAttribute("title", "Click to logout")
        logoutButton.classList.add("apply-common-tooltip")
        logoutButton.style.cursor = "pointer"
        logoutButton.addEventListener("click", () =>
            widgetInstance.showConfirmDialog({
                title: "Confirmation",
                body: "Are you sure you want to logout?",
                mainButtonText: "Logout",
                cancelButtonText: "Cancel",
                callback: (isApproved: any) => {
                    if (isApproved) contextAuth.logout()
                }
            })
        )
        logoutButton.innerHTML = "Logout"
    }

    const widgetReadyCalculator = (widgetInstance: any) => {
        const logoutButton = widgetInstance.createButton({
            align: "right"
        })
        logoutButton.setAttribute("title", "Calculator")
        logoutButton.classList.add("apply-common-tooltip")
        logoutButton.style.cursor = "pointer"
        logoutButton.addEventListener("click", () => setIsShowCalculatorHandler())

        logoutButton.innerHTML = `<svg height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" fill="currentColor"><path d="M176,60H80a4,4,0,0,0-4,4v48a4,4,0,0,0,4,4h96a4,4,0,0,0,4-4V64A4,4,0,0,0,176,60Zm-4,48H84V68h88Zm28-80H56A12,12,0,0,0,44,40V216a12,12,0,0,0,12,12H200a12,12,0,0,0,12-12V40A12,12,0,0,0,200,28Zm4,188a4,4,0,0,1-4,4H56a4,4,0,0,1-4-4V40a4,4,0,0,1,4-4H200a4,4,0,0,1,4,4ZM96,148a8,8,0,1,1-8-8A8,8,0,0,1,96,148Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,136,148Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,176,148ZM96,188a8,8,0,1,1-8-8A8,8,0,0,1,96,188Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,136,188Zm40,0a8,8,0,1,1-8-8A8,8,0,0,1,176,188Z"/></svg>`
    }

    const getInitialSymbolName = async (symbols: any[]): Promise<string> => {
        let setting: Setting | undefined = undefined

        try {
            setting = await RemoteSettingsManager.defaultManager().getValue(CURRENT_SYMBOL_KEY, contextAuth.loginAccountId!)
        } catch (error) {
        }

        const savedSymbol = setting?.value
        return (
            symbols.find((symbol: any) => symbol.name === savedSymbol)?.name ||
            symbols.find((symbol: any) => symbol.name.includes("EURUSD"))?.name ||
            symbols[0]?.name ||
            savedSymbol
        )
    }

    const saveInitialSymbolName = React.useCallback(async (name: string) => {
        if (contextAuth.loginAccountId) {
            try {
                await RemoteSettingsManager.defaultManager().setValue(CURRENT_SYMBOL_KEY, contextAuth.loginAccountId, name)
            } catch (error) {
            }
        }
    }, [contextAuth])

    return (
        <div className={styles.tradingViewWrapper}>
            <div
                ref={chartContainerRef}
                className={styles.tradingViewWidget}
            />
            <div>
                {
                    isShowCalculator && <SideBarWrapper
                        title={"Trading Calculator"}
                        closeCallback={() => setIsShowCalculatorHandler()}
                    >
                        <CalculatorWrapper
                            theme={theme}
                        ></CalculatorWrapper>
                    </SideBarWrapper>
                }
            </div>
        </div>
    )

}
