import {CommonAccountManagerColumnId} from "../../../assets/lib/charting_library/broker-api"
import {OrderStatusFilter, StandardFormatterName} from "../../../assets/lib/charting_library"

export const ordersPageColumns = [
    {
        label: "Symbol",
        formatter: StandardFormatterName.Symbol,
        id: CommonAccountManagerColumnId.Symbol,
        dataFields: ["symbol", "symbol", "message"]
    },
    {
        label: "Ticket",
        id: "id",
        dataFields: ["id"],
        sortable: true,
        help: "Identifier for the order"
    },
    {
        label: "Time",
        id: "time",
        dataFields: ["time"],
        formatter: StandardFormatterName.LocalDate
    },
    {
        label: "Side",
        id: "side",
        dataFields: ["side"],
        formatter: "side"
    },
    {
        label: "Order Type",
        id: "type",
        dataFields: ["type", "parentId", "stopType"],
        formatter: "type"
    },
    {
        label: "Status",
        id: "status",
        dataFields: ["status"],
        formatter: "status",
        supportedStatusFilters: [OrderStatusFilter.All]
    },
    {
        label: "Volume",
        alignment: "right",
        id: "qty",
        dataFields: ["qty"],
        help: "Size in lots",
        formatter: "formatQuantity",
        showZeroValues: true
    },
    {
        label: "Price",
        alignment: "right",
        id: "avgPrice",
        dataFields: ["avgPrice"],
        formatter: "formatPrice"
    },
    {
        label: "Stop Loss",
        alignment: "right",
        id: "stopLoss",
        dataFields: ["stopLoss"],
        formatter: "formatPrice"
    },
    {
        label: "Take Profit",
        alignment: "right",
        id: "takeProfit",
        dataFields: ["takeProfit"],
        formatter: "formatPrice"
    },
    {
        label: "Last Price",
        alignment: "right",
        id: "last",
        dataFields: ["last"],
        formatter: "formatPriceForexSup",
        highlightDiff: true
    }
]

export const positionsPageColumns = [
    {
        label: "Symbol",
        formatter: "symbol",
        id: CommonAccountManagerColumnId.Symbol,
        dataFields: ["symbol", "symbol", "message"]
    },
    {
        label: "Ticket",
        id: "id",
        dataFields: ["id"],
        formatter: (value: number) => value,
        sortable: true,
        help: "Identifier for the position"
    },
    {
        label: "Time",
        id: "time",
        dataFields: ["time"],
        formatter: StandardFormatterName.LocalDate
    },
    {
        label: "Side",
        id: "side",
        dataFields: ["side"],
        formatter: "side"
    },
    {
        label: "Volume",
        alignment: "right",
        id: "qty",
        dataFields: ["qty"],
        help: "Size in lots",
        formatter: "formatQuantity"
    },
    {
        label: "Price",
        alignment: "right",
        id: "avgPrice",
        dataFields: ["avgPrice"],
        formatter: "formatPrice"
    },
    {
        label: "Stop Loss",
        alignment: "right",
        id: "stopLoss",
        dataFields: ["stopLoss"],
        formatter: "formatPrice"
    },
    {
        label: "Take Profit",
        alignment: "right",
        id: "takeProfit",
        dataFields: ["takeProfit"],
        formatter: "formatPrice"
    },
    {
        label: "Last Price",
        alignment: "right",
        id: "last",
        dataFields: ["last"],
        formatter: "formatPriceForexSup",
        highlightDiff: true
    },
    {
        label: "Swap",
        alignment: "right",
        id: "swap",
        dataFields: ["swap"],
        formatter: "fixed",
        showZeroValues: false
    },
    {
        label: "Profit",
        alignment: "right",
        id: "pl",
        dataFields: ["pl"],
        formatter: "profitInInstrumentCurrency",
        showZeroValues: true
    }
]

export const historyPageColumns = [
    {
        label: "Symbol",
        formatter: StandardFormatterName.Symbol,
        id: CommonAccountManagerColumnId.Symbol,
        dataFields: ["symbol", "symbol", "message"],
        showZeroValues: false
    },
    {
        label: "Deal",
        id: "id",
        dataFields: ["id"],
        sortable: true
    },
    {
        label: "Time",
        id: "time",
        dataFields: ["time"],
        formatter: StandardFormatterName.LocalDate
    },
    {
        label: "Side",
        id: "side",
        dataFields: ["side"],
        formatter: "side"
    },
    {
        label: "Volume",
        alignment: "right",
        id: "qty",
        dataFields: ["qty"],
        help: "Size in lots",
        formatter: "formatQuantity",
        showZeroValues: true
    },
    {
        label: "Price",
        alignment: "right",
        id: "avgPrice",
        dataFields: ["avgPrice"],
        formatter: "formatPrice",
        showZeroValues: true
    },
    {
        label: "Stop Loss",
        alignment: "right",
        id: "stopLoss",
        dataFields: ["stopLoss"],
        formatter: "formatPrice",
        showZeroValues: false
    },
    {
        label: "Take Profit",
        alignment: "right",
        id: "takeProfit",
        dataFields: ["takeProfit"],
        formatter: "formatPrice",
        showZeroValues: false
    },
    {
        label: "Commission",
        alignment: "right",
        id: "commission",
        dataFields: ["commission"],
        formatter: (value: number) => value,
        showZeroValues: false
    },
    {
        label: "Swap",
        alignment: "right",
        id: "swap",
        dataFields: ["swap"],
        formatter: "fixed",
        hideByDefault: true,
        showZeroValues: false
    },
    {
        label: "Profit",
        alignment: "right",
        id: "pl",
        dataFields: ["pl"],
        formatter: "profitInInstrumentCurrency",
        showZeroValues: true
    }
]

export const fundOperationsPageColumns = [
    {
        label: "Deal",
        id: "id",
        dataFields: ["id"],
        formatter: "text"
    },
    {
        label: "Time",
        id: "time",
        dataFields: ["time"],
        formatter: StandardFormatterName.LocalDate
    },
    {
        label: "Operation",
        id: "operation",
        dataFields: ["operation"],
        formatter: "text"
    },
    {
        label: "Comment",
        id: "comment",
        dataFields: ["comment"],
        formatter: "text"
    },
    {
        label: "Amount",
        alignment: "right",
        id: "pl",
        dataFields: ["pl"],
        formatter: "profitInInstrumentCurrency",
        showZeroValues: true
    }
]