import React from "react"
import "./assets/styles/index.scss"
import App from "./App"
import { createRoot } from 'react-dom/client';

function injectAmplitudeScript() {
    const amplitudeScript = document.createElement('script');
    amplitudeScript.src = 'https://cdn.amplitude.com/script/b49c4d5cbca201293c9f3cb078dee000.js';
    amplitudeScript.async = true;
    document.head.appendChild(amplitudeScript);

    const initScript = document.createElement('script');
    initScript.textContent = `
    window.amplitude.add(window.sessionReplay.plugin({sampleRate: 1}));
    window.amplitude.init('b49c4d5cbca201293c9f3cb078dee000', {"fetchRemoteConfig":true,"autocapture":true});
  `;
    amplitudeScript.onload = () => {
        document.head.appendChild(initScript);
    };
}

if (process.env.REACT_APP_NODE_ENV === 'production') {
    injectAmplitudeScript();
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <App/>
)