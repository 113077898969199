import jsPDF from "jspdf"
import autoTable, {RowInput} from "jspdf-autotable"

interface PdfTableData {
    head: RowInput[];
    body: RowInput[];
    title: string;
}

const loadImage = (url: string): Promise<HTMLImageElement | null> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = () => resolve(null);
    });
};

export const generatePdfTable = async (
    fileName: string,
    tables: PdfTableData[]
) => {
    const doc = new jsPDF()
    let currentY = 10

    const imageUrl = '/logo-pdf.png';
    const image = await loadImage(imageUrl);

    if (image) {
        doc.addImage(image, 'PNG', 75, currentY, 60, 0);
        currentY += 28;
    }

    tables.forEach((table) => {
        doc.setFontSize(15)
        doc.text(table.title, 3, currentY)
        currentY += 6

        autoTable(doc, {
            startY: currentY,
            theme: "grid",
            margin: 3,
            styles: {
                fontSize: 8,
                halign: "center",
                textColor: [16, 24, 40]
            },
            headStyles: {
                fillColor: [74, 85, 101],
                textColor: [255, 255, 255],
                cellPadding: {
                    horizontal: 1,
                    vertical: 3
                }
            },
            bodyStyles: {
                cellPadding: {
                    horizontal: 1,
                    vertical: 3
                }
            },
            head: table.head,
            body: table.body
        })

        //@ts-ignore
        currentY = doc.lastAutoTable.finalY + 13
    })

    doc.save(`${fileName}.pdf`)
}
