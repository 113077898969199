import {
    AccountId,
    AccountManagerColumn,
    AccountManagerInfo,
    AccountManagerSummaryField,
    AccountMetainfo,
    ActionMetaInfo,
    Brackets,
    ConnectionStatus,
    DefaultContextMenuActionsParams,
    Execution,
    IBrokerConnectionAdapterHost,
    IDelegate,
    InstrumentInfo,
    IWatchedValue,
    MenuSeparator,
    Order,
    OrderStatus,
    OrderTableColumn,
    OrderType,
    PlaceOrderResult,
    Position,
    PreOrder,
    Side,
    StandardFormatterName,
    TradeContext
} from "../../../assets/lib/charting_library/broker-api"
import calculationFunctions from "../../../assets/utils/calculationFunctions"

import {DatafeedQuoteValues, IDatafeedQuotesApi, QuoteData} from "../../../assets/lib/charting_library/datafeed-api"
import {
    fundOperationsPageColumns,
    historyPageColumns,
    ordersPageColumns,
    positionsPageColumns
} from "../utils/columnsSettings"
import {$fetch} from "../../../assets/utils/fetch"
import {IAuth} from "../../../context/auth"
import {defaultConfig, IConfig} from "../../../context/config"
import {
    IBrokerCommon,
    IBrokerTerminal,
    IsTradableResult,
    LibrarySymbolInfo,
    ParentType
} from "../../../assets/lib/charting_library"
import {WebSocketsTradeEvents} from "../websockets/WebSocketsTradeEvents"
import {
    defineFundOperationType, defineOrderTypeAndSide,
    defineTradeExecutionErrorType
} from "../utils/switchState"

interface SimpleMap<TValue> {
    [key: string]: TValue;
}

interface AccountManagerData {
    title: string;
    balance: number;
    promo: number;
    equity: number;
    margin: number;
    freeMargin: number;
    marginLevel: number;
    pl: number;
}

interface IDatafeedQuotesApiExtended extends IDatafeedQuotesApi {
    [key: string]: any
}

export class Broker implements IBrokerTerminal {
    private static instance: Broker | null = null
    private readonly _host: IBrokerConnectionAdapterHost
    private _accountManagerData: AccountManagerData = {
        title: "",
        balance: 0,
        promo: 0,
        equity: 0,
        margin: 0,
        freeMargin: 0,
        pl: 0,
        marginLevel: 0
    }

    private _accountManagerDataNative: any
    private _amChangeDelegate!: IDelegate<(values: AccountManagerData) => void>
    private _fundsOperationsDelegate!: IDelegate<(values: any) => void>
    private _balanceValue: IWatchedValue<number> | any
    private _promo: IWatchedValue<number> | any
    private _equityValue: IWatchedValue<number> | any
    private _margin: IWatchedValue<number> | any
    private _freeMargin: IWatchedValue<number> | any
    private _marginLevel: IWatchedValue<number> | any
    private _pl: IWatchedValue<number> | any
    private readonly _positionById: SimpleMap<Position> = {}
    private readonly _orderById: SimpleMap<Order> = {}
    private _executions: Execution[] = []
    private _fundsOperations: any
    private readonly _quotesProvider: IDatafeedQuotesApiExtended
    private readonly contextAuth!: IAuth
    private applicationConfiguration: IConfig = defaultConfig
    private symbolsMap: Map<string, LibrarySymbolInfo> = new Map()
    private symbolsAllMap: Map<string, LibrarySymbolInfo> = new Map()
    private workerInstance: Worker | null = null

    constructor(
        host: IBrokerConnectionAdapterHost,
        quotesProvider: IDatafeedQuotesApi,
        logicDependencies: {
            applicationConfiguration: IConfig,
            contextAuth: IAuth,
            symbols: LibrarySymbolInfo[],
            allSymbols: LibrarySymbolInfo[],
            webSocketsTradeEventsInstance: WebSocketsTradeEvents
        }
    ) {
        this.contextAuth = logicDependencies.contextAuth
        this.applicationConfiguration = logicDependencies.applicationConfiguration
        logicDependencies.symbols.forEach(symbol => this.symbolsMap.set(symbol.name, symbol))
        logicDependencies.allSymbols.forEach(symbol => this.symbolsAllMap.set(symbol.name, symbol))
        this._quotesProvider = quotesProvider
        this._host = host
        this._fundsOperations = this._host.factory.createWatchedValue([])
        this._fundsOperationsDelegate = this._host.factory.createDelegate()
        this._fundsOperationsDelegate.subscribe(null, (values) => {
            this._fundsOperations.setValue([...this._fundsOperations._value, ...values])
        })
        this._bindAccountWatchedValues()

        ;(async () => {
            await this._fetchAccountManagerData()
            await this._fetchOrdersAndPositionsData()
            logicDependencies.webSocketsTradeEventsInstance.subscribe(this._tradeEventsHandler.bind(this))
            this.startEngine()
        })()
    }

    static setInstance(
        host: IBrokerConnectionAdapterHost,
        quotesProvider: IDatafeedQuotesApi,
        logicDependencies: {
            applicationConfiguration: IConfig,
            contextAuth: IAuth,
            symbols: LibrarySymbolInfo[],
            allSymbols: LibrarySymbolInfo[],
            webSocketsTradeEventsInstance: WebSocketsTradeEvents
        }
    ){
        if (!Broker.instance) {
            Broker.instance = new Broker(host, quotesProvider, logicDependencies)
        }
        return Broker.instance
    }

    static getInstance(){
        return Broker.instance
    }

    static removeInstance(){
        if (Broker.instance) {
            Broker.instance.stopEngine()
            Broker.instance = null
        }
    }

    private startEngine(): void {
        if (this.workerInstance) return
        this.workerInstance = new Worker(new URL("../../../assets/utils/workerLoopEngine.ts", import.meta.url), {type: "module"})
        this.workerInstance.onmessage = async (e: MessageEvent) => {
            await this._tickDataHandlerEngine(this._quotesProvider.quoteTickStateForTicket)
        }
        this.workerInstance.postMessage({
            command: "start",
            intervalMs: 700
        })
    }

    stopEngine(): void {
        if (!this.workerInstance) return
        this.workerInstance.postMessage({command: "stop"})
        this.workerInstance.terminate()
        this.workerInstance = null
    }

    private _bindAccountWatchedValues(): void {
        this._amChangeDelegate = this._host.factory.createDelegate()
        this._balanceValue = this._host.factory.createWatchedValue(this._accountManagerData.balance)
        this._promo = this._host.factory.createWatchedValue(this._accountManagerData.promo)
        this._equityValue = this._host.factory.createWatchedValue(this._accountManagerData.equity)
        this._margin = this._host.factory.createWatchedValue(this._accountManagerData.margin)
        this._freeMargin = this._host.factory.createWatchedValue(this._accountManagerData.freeMargin)
        this._marginLevel = this._host.factory.createWatchedValue(this._accountManagerData.marginLevel)
        this._pl = this._host.factory.createWatchedValue(this._accountManagerData.pl)

        this._amChangeDelegate.subscribe(null, (values: AccountManagerData) => {
            this._balanceValue!.setValue(values.balance)
            this._promo!.setValue(values.promo)
            this._equityValue!.setValue(values.equity)
            this._margin!.setValue(values.margin)
            this._freeMargin!.setValue(values.freeMargin)
            this._marginLevel!.setValue(values.marginLevel)
            this._pl!.setValue(values.pl)
        })
    }

    private _recalculateAMData(): void {
        let pl = Object.values(this._positionById).reduce((acc, position) => {
            acc += (position.pl || 0) + (position.swap || 0)
            return acc
        }, 0)
        const equity = this._accountManagerData.balance + pl + this._accountManagerData.promo
        this._accountManagerData.pl = pl
        this._accountManagerData.equity = equity
        this._accountManagerData.freeMargin = equity - this._accountManagerData.margin
        this._accountManagerData.marginLevel = (equity && this._accountManagerData.margin)
            ? ((equity / this._accountManagerData.margin) * 100)
            : 0
        this._amChangeDelegate.fire(this._accountManagerData)
    }

    private async _fetchAccountManagerData(): Promise<void> {
        const {loginAccountId} = this.contextAuth
        try {
            {
                const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/accounts/info?login=${loginAccountId}`)
                this._accountManagerDataNative = await response.json()
            }
            const {
                balance,
                equity,
                floating: pl,
                margin_free: freeMargin,
                margin,
                credit: promo,
                margin_level: marginLevel,
            } = this._accountManagerDataNative

            this._accountManagerData = {
                title: `${loginAccountId}`,
                balance,
                promo,
                equity,
                margin,
                freeMargin,
                pl,
                marginLevel,
            }
            this._balanceValue!.setValue(balance)
            this._promo!.setValue(promo)
            this._equityValue!.setValue(equity)
            this._margin!.setValue(margin)
            this._freeMargin!.setValue(freeMargin)
            this._marginLevel!.setValue(marginLevel)
            this._pl!.setValue(pl)
            this._amChangeDelegate.fire(this._accountManagerData)
        } catch (error) {
            console.error("Error fetching account data:", error)
        }
    }

    private async _fetchOrdersAndPositionsData() {
        try {
            const {loginAccountId} = this.contextAuth
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/orders/${loginAccountId}`)
            const data = await response.json()
            const {orders = [], positions = []} = data
            for (const position of this._mapperForPositions(positions)) {
                this._updatePosition(position)
                this._updateOrderWithBracket(position, "Position")
            }
            for (const order of this._mapperForOrders(orders)) {
                this._updateOrder(order)
                this._updateOrderWithBracket(order, "Order")
            }
            this._recalculateAMData()
        } catch (error) {
            console.error("Error fetching orders/positions data:", error)
        }
    }

    async ordersHistory(): Promise<Order[]> {
        const {mappedHistories} = await this._fetchHistoryCustom()
        return mappedHistories
    }

    async _fetchHistoryCustom(): Promise<{ mappedFundsOperations: any[], mappedHistories: any[] }> {
        try {
            const {loginAccountId} = this.contextAuth
            const allItems: any[] = []
            let hasMore = true
            let page = 1
            while (hasMore) {
                const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/history/${loginAccountId}/deals?page=${page}&page_size=${1000}`)
                const {items, has_more} = await response.json()
                allItems.push(...items)
                hasMore = has_more
                page++
            }
            const lastItemsToSet = allItems.slice(-30)
            const {mappedFundsOperations, mappedHistories} = this._mapperForHistory(lastItemsToSet)
            this._fundsOperationsDelegate.fire(mappedFundsOperations)
            return {
                mappedFundsOperations,
                mappedHistories
            }
        } catch (e) {
            console.error("Error fetching orders/positions data:", e)
            return {
                mappedFundsOperations: [],
                mappedHistories: []
            }
        }
    }

    private _mapperForOrders(orders: any[]): Order[] {
        return [...orders].reverse().map((order) => {
            const {type, side} = this._defineOrderTypeAndSide(order.type)
            const tradingViewOrder: Order = {
                id: String(order.ticket),
                symbol: order.symbol,
                type,
                side,
                time: new Date(order.time_setup).getTime(),
                avgPrice: order.price_order,
                qty: order.volume_current_lots,
                status: this._defineOrderStatus(order.state),
                updateTime: new Date(order.time_setup).getTime(),
                last: order.price_current
            }
            if (order.sl) tradingViewOrder.stopLoss = order.sl
            if (order.tp) tradingViewOrder.takeProfit = order.tp
            if (order.type === 2 || order.type === 3) tradingViewOrder.limitPrice = order.price_order
            if (order.type === 4 || order.type === 5) tradingViewOrder.stopPrice = order.price_order
            return tradingViewOrder
        })
    }

    private _mapperForPositions(positions: any[]): Position[] {
        return [...positions].reverse().map((position) => {
            const tradingViewPosition: Position = {
                id: String(position.ticket),
                symbol: position.symbol,
                qty: position.volume_lots,
                side: position.action === 0 ? Side.Buy : Side.Sell,
                avgPrice: position.price_open,
                last: position.price_current,
                pl: position.profit,
                swap: position.swap,
                time: new Date(position.time_create).getTime()
            }
            if (position.sl) tradingViewPosition.stopLoss = position.sl
            if (position.tp) tradingViewPosition.takeProfit = position.tp
            return tradingViewPosition
        })
    }

    private _mapperForHistory(histories: any[]): { mappedFundsOperations: any[], mappedHistories: any[] } {
        const mappedFundsOperations = histories.filter((history) => !history.symbol).map((history) => {
            return {
                id: String(history.ticket),
                pl: history.profit,
                time: new Date(history.time).getTime(),
                operation: this._defineFundOperationType(history.action),
                comment: history.comment.trim()
            }
        })
        const mappedHistories = histories.filter(history => history.symbol).map((history) => {
            const {type, side} = this._defineOrderTypeAndSide(history.action)
            return {
                id: String(history.ticket),
                symbol: history.symbol,
                time: new Date(history.time).getTime(),
                side,
                type,
                swap: history.swap,
                qty: history.volume_lots,
                avgPrice: history.price,
                stopLoss: history.sl,
                takeProfit: history.tp,
                commission: history.commission,
                pl: history.profit,
                status: OrderStatus.Filled
            }
        })
        return {
            mappedFundsOperations,
            mappedHistories
        }
    }

    private async _tradeEventsHandler(data: any) {
        switch (data.trading_event_type) {
            case "order.add":
            case "order.update": {
                const [order] = this._mapperForOrders([data])
                this._updateOrder(order)
                this._updateOrderWithBracket(order, "Order")
                break
            }
            case "order.delete": {
                const [order] = this._mapperForOrders([data])
                const mutatedOrder = {...order, qty: 0, pl: 0}
                this._updateOrder(mutatedOrder)
                this._updateOrderWithBracket(mutatedOrder, "Order")
                break
            }
            case "deal.add": {
                const {mappedFundsOperations, mappedHistories} = this._mapperForHistory([data])
                if (mappedFundsOperations.length) {
                    this._fundsOperationsDelegate.fire([...mappedFundsOperations])
                }
                if (mappedHistories.length) {
                    const [history] = mappedHistories
                    this._updateOrder(history)
                    this._createExecution(history)
                }
                await this._fetchAccountManagerData()
                break
            }
            case "position.add":
            case "position.update": {
                const [position] = this._mapperForPositions([data])
                this._updatePosition(position)
                this._updateOrderWithBracket(position, "Position")
                this._recalculateAMData()
                break
            }
            case "position.delete": {
                const [position] = this._mapperForPositions([data])
                const mutatedPosition = {...position, qty: 0}
                this._updatePosition(mutatedPosition)
                this._updateOrderWithBracket(mutatedPosition, "Position")
                this._recalculateAMData()
                break
            }
        }
        switch (data.trading_event_type) {
            case "trade.execution.error": {
                let baseMessage = `An error occurred while executing the ${this._defineTradeExecutionErrorType(data.error_type)} order.\n`
                baseMessage += `Symbol: ${data.symbol}, Volume: ${data.volume_lots} lots. \n`
                baseMessage += `Please verify the price and volume, then try to execute the operation again.`
                this._host.showNotification("Trade execution error ", baseMessage, 0)
                break
            }
            case "position.add":
            case "position.delete": {
                const symbolInfo = this.symbolsMap.get(data.symbol)
                const toFixed = symbolInfo?.library_custom_fields?.digits
                const priceOpen = toFixed ? data.price_open.toFixed(toFixed) : data.price_open
                const profit = data.profit.toFixed(2)
                let baseMessage = `${data.trading_event_type === "position.add" ? data.action === 0 ? "BOUGHT" : "SOLD" : "CLOSED"} ${data.volume_lots} lots of ${data.symbol} ${data.trading_event_type === "position.delete" ? "with a profit of " + profit : "at " + priceOpen}   \n`
                if (data.tp) baseMessage += `Take Profit: ${data.tp}\n`
                if (data.sl) baseMessage += `Stop Loss: ${data.sl}\n`
                this._host.showNotification(`Position ${data.position} ${data.trading_event_type === "position.add" ? "executed" : ""}`, baseMessage, 1)
                break
            }
        }
    }

    public subscribeEquity(): void {
        this._equityValue.subscribe(this._handleEquityUpdate, {callWithLast: true})
    }

    public unsubscribeEquity(): void {
        this._equityValue.unsubscribe(this._handleEquityUpdate)
    }

    connectionStatus(): ConnectionStatus {
        return ConnectionStatus.Connected
    }

    public chartContextMenuActions(context: TradeContext, options?: DefaultContextMenuActionsParams): Promise<ActionMetaInfo[]> {
        return this._host.defaultContextMenuActions(context)
    }

    public isTradable(symbol: string): Promise<boolean | IsTradableResult> {
        const notTradableReasonObject = {
            tradable: false,
            shortReason: "This symbol is not available to trade at the moment. Please try a different one.",
            reason: `The symbol ${symbol} is currently unavailable for trading. Please check the Trading Session Calendar or review notifications for potential holidays. Alternatively, we invite you to explore other trading pairs or 24/7 markets, such as Crypto or our Synthetic Q-Volatility Indices, to continue trading.`
        }

        const symbolInfo = this.symbolsMap.get(symbol)
        if (!symbolInfo) return Promise.resolve(notTradableReasonObject)

        const date = new Date()
        const dayOfWeek = date.getUTCDay()
        const sessionIndex = (dayOfWeek + 6) % 7

        const {library_custom_fields: {originalSessions}} = symbolInfo as any
        const todaySessions = originalSessions[sessionIndex] || []
        if (!todaySessions.length) return Promise.resolve(notTradableReasonObject)

        const currentMinutes = date.getUTCHours() * 60 + date.getUTCMinutes()

        const timeToMinutes = (timeStr: string) => {
            const [hours, minutes] = timeStr.split(":").map(Number)
            return hours * 60 + minutes
        }

        for (const session of todaySessions) {
            const startMinutes = timeToMinutes(session.start)
            const endMinutes = timeToMinutes(session.end)
            if (currentMinutes >= startMinutes && currentMinutes <= endMinutes) {
                return Promise.resolve(true)
            }
        }
        return Promise.resolve(notTradableReasonObject)
    }

    async placeOrder(preOrder: PreOrder): Promise<PlaceOrderResult> {
        const {qty: volumeInLots} = preOrder
        const orderBasic: any = {
            login: this.contextAuth.loginAccountId,
            symbol: preOrder.symbol,
            volume: Math.round(volumeInLots * 10000),
            type: preOrder.side === Side.Buy ? 0 : 1
        }
        if (preOrder.stopLoss) orderBasic["sl"] = preOrder.stopLoss
        if (preOrder.takeProfit) orderBasic["tp"] = preOrder.takeProfit
        if (preOrder.limitPrice) {
            if (preOrder.side === Side.Buy && preOrder.type === OrderType.Limit) orderBasic.type = 2
            if (preOrder.side === Side.Sell && preOrder.type === OrderType.Limit) orderBasic.type = 3
            orderBasic.price = preOrder.limitPrice
        }
        if (preOrder.stopPrice) {
            if (preOrder.side === Side.Buy && preOrder.type === OrderType.Stop) orderBasic.type = 4
            if (preOrder.side === Side.Sell && preOrder.type === OrderType.Stop) orderBasic.type = 5
            orderBasic.price = preOrder.stopPrice
        }
        if (preOrder.duration) {
            const {type = "DAY"} = preOrder.duration as any
            orderBasic.time_type = type === "DAY" ? 1 : 0
        }
        try {
            const response = await $fetch.post(`${this.applicationConfiguration.urlRestAPI}/api/v1/orders/new`, {
                body: JSON.stringify(orderBasic)
            })
            await response.json()
        } catch (e: any) {
            if (e?.context?.app_code === 6) {
                this._host.showNotification("Not Enough Money", "Add funds to your trading account in the client portal", 0)
            } else {
                this._host.showNotification("Error", e.detail, 0)
            }
        }
        return {}
    }

    async modifyOrder(order: Order): Promise<void> {
        try {
            const {stopLoss = null, takeProfit = null} = this.checkModifyOrderNullExceptZero(order)
            const modifyBasic: any = {
                login: parseInt(this.contextAuth.loginAccountId as unknown as string),
                ticket: parseInt(order.id),
                sl: stopLoss,
                tp: takeProfit
            }
            if (!order.parentId) {
                if (order.limitPrice) modifyBasic.price = order.limitPrice
                if (order.stopPrice) modifyBasic.price = order.stopPrice
            } else {
                let parent = this._positionById[order.parentId] ?? this._orderById[order.parentId]
                if (!parent) return this._host.showNotification("Error", "Parent order not found", 0)

                modifyBasic.ticket = parseInt(parent.id)
                if (parent.stopLoss) modifyBasic.sl = parent.stopLoss
                if (parent.takeProfit) modifyBasic.tp = parent.takeProfit
                if (order.limitPrice) modifyBasic.tp = order.limitPrice
                if (order.stopPrice) modifyBasic.sl = order.stopPrice
            }
            const response = await $fetch.patch(`${this.applicationConfiguration.urlRestAPI}/api/v1/orders/modify`, {
                body: JSON.stringify(modifyBasic)
            })
            if (response.ok && order.parentId) {
                this._host.orderUpdate(order)
            }
        } catch (e: any) {
            this._host.showNotification("Error", e.detail, 0)
        }
    }

    async editPositionBrackets(positionId: string, modifiedBrackets: Brackets): Promise<void> {
        try {
            const {stopLoss = null, takeProfit = null} = this.checkModifyOrderNullExceptZero(modifiedBrackets)
            const modifyBasic: any = {
                login: parseInt(this.contextAuth.loginAccountId as unknown as string),
                ticket: parseInt(positionId),
                sl: stopLoss,
                tp: takeProfit
            }
            await $fetch.patch(`${this.applicationConfiguration.urlRestAPI}/api/v1/orders/modify`, {
                body: JSON.stringify(modifyBasic)
            })
        } catch (e: any) {
            this._host.showNotification("Error", e.detail, 0)
        }
    }

    private checkModifyOrderNullExceptZero(entity: Order | Brackets) {
        const clonedEntity = {...entity}
        if (clonedEntity.stopLoss === 0) clonedEntity.stopLoss = undefined
        if (clonedEntity.takeProfit === 0) clonedEntity.takeProfit = undefined
        return clonedEntity
    }

    async closePosition(positionId: string, amount?: number): Promise<void> {
        try {
            await this.deletePositionOrOrder(positionId, amount)
        } catch (e: any) {
            this._host.showNotification("Error", e.detail, 0)
        }
    }

    async cancelOrder(orderId: string): Promise<void> {
        const orderToCancel = this._orderById[orderId]
        if (orderToCancel.parentId) {
            const isRemoveTakeProfit = orderToCancel.id.includes("TP")
            const isRemoveStopLoss = orderToCancel.id.includes("SL")

            let parent = this._positionById[orderToCancel.parentId] ?? this._orderById[orderToCancel.parentId]

            if (orderToCancel.parentType === ParentType.Position) {
                if (isRemoveTakeProfit) await this.editPositionBrackets(parent.id, {...parent, takeProfit: undefined})
                if (isRemoveStopLoss) await this.editPositionBrackets(parent.id, {...parent, stopLoss: undefined})
            }
            if (orderToCancel.parentType === ParentType.Order) {
                if (isRemoveTakeProfit) await this.modifyOrder({...parent, takeProfit: undefined} as Order)
                if (isRemoveStopLoss) await this.modifyOrder({...parent, stopLoss: undefined} as Order)
            }
            return
        }
        try {
            await this.deletePositionOrOrder(orderId)
        } catch (e: any) {
            this._host.showNotification("Error", e.detail, 0)
        }
    }

    private async deletePositionOrOrder(id: string, volume: number | null = null): Promise<void> {
        const login = this.contextAuth.loginAccountId
        if (volume) volume = Math.round(volume * 10000)
        await $fetch.patch(`${this.applicationConfiguration.urlRestAPI}/api/v1/orders/${login}/close`, {
            body: JSON.stringify({
                login,
                ticket: id,
                volume: volume
            })
        })
    }

    async orders(): Promise<Order[]> {
        return Object.values(this._orderById)
    }

    async positions(): Promise<Position[]> {
        return Object.values(this._positionById)
    }

    async executions(symbol: string): Promise<Execution[]> {
        const {mappedHistories} = await this._fetchHistoryCustom()
        mappedHistories.forEach((history) => this._createExecution(history))
        return Promise.resolve(this._executions
            .filter((data: Execution) => {
                return data.symbol === symbol
            })
        )
    }

    public async reversePosition(positionId: string): Promise<void> {
        const position = this._positionById[positionId]
        const handler = () => {
            return this.placeOrder({
                symbol: position.symbol,
                side: position.side === Side.Sell ? Side.Buy : Side.Sell,
                type: OrderType.Market,
                qty: position.qty
            } as unknown as PreOrder)
        }
        await handler()
    }

    //@ts-ignore
    public accountManagerInfo(): AccountManagerInfo {
        let summary: AccountManagerSummaryField[] = [
            {
                text: "Account Balance",
                wValue: this._balanceValue,
                formatter: StandardFormatterName.Fixed,
                isDefault: true
            },
            {
                text: "Promo",
                wValue: this._promo,
                formatter: StandardFormatterName.Fixed,
                isDefault: true
            },
            {
                text: "Equity",
                wValue: this._equityValue,
                formatter: StandardFormatterName.Fixed,
                isDefault: true
            },
            {
                text: "Unrealized P&L",
                wValue: this._pl,
                formatter: StandardFormatterName.Profit,
                isDefault: true
            },
            {
                text: "Account Margin",
                wValue: this._margin,
                formatter: StandardFormatterName.Fixed,
                isDefault: true
            },
            {
                text: "Available Funds",
                wValue: this._freeMargin,
                formatter: StandardFormatterName.Fixed,
                isDefault: true
            },
            {
                text: "Margin Level",
                wValue: this._marginLevel,
                formatter: StandardFormatterName.Percentage,
                isDefault: true
            },
        ]

        return {
            accountTitle: "",
            summary,
            orderColumns: ordersPageColumns as OrderTableColumn[],
            positionColumns: positionsPageColumns as AccountManagerColumn[],
            historyColumns: historyPageColumns as AccountManagerColumn[],
            pages: [
                {
                    id: "fundsOperations",
                    title: "Funds Operations",
                    tables: [
                        {
                            id: "fundsOperations",
                            changeDelegate: this._fundsOperationsDelegate,
                            columns: fundOperationsPageColumns as AccountManagerColumn[],
                            getData: (): Promise<[]> => {
                                return Promise.resolve(this._fundsOperations._value)
                            }
                        }
                    ]
                }
            ],
            contextMenuActions: (contextMenuEvent: MouseEvent, activePageActions: ActionMetaInfo[]) => {
                return Promise.resolve(this._bottomContextMenuItems(activePageActions))
            },
            supportPagination: true
        } as AccountManagerInfo
    }

    private findLastTickDataFromTicks(symbol: string): null | { ask: number, bid: number } {
        const entries = Array.from(this._quotesProvider.lastTickData.entries()) as [string, any][]
        const lastTickData = entries.find(([key]) => key.includes(symbol))
        if (!lastTickData) return null
        const [, tickData] = lastTickData
        return tickData || null
    }

    private async findLastTickDataFromRequest(symbol: string): Promise<null | { ask: number, bid: number }> {
        try {
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/symbols/${symbol}/ticks/last`)
            if (!response.ok) return null
            return await response.json()
        } catch (e) {
            return null
        }
    }

    private async findCrossCurrencyRate({currencyFrom, currencyTo}: {
        currencyFrom: string,
        currencyTo: string
    }, callbacks: Function[]): Promise<{ askRate: number, bidRate: number }> {
        const findConversionPairRates = async (symbol: string): Promise<{ ask: number, bid: number } | null> => {
            let tickData = null
            for await (const callback of callbacks) tickData = await callback(symbol)
            return tickData
        }
        const hasConversionPair = (symbol: string) => Array.from(this.symbolsAllMap.keys()).some((i: string) => i.includes(symbol))
        const currencyConversion = "USD"
        let tickDataSameSame = {ask: 1, bid: 1}
        let tickDataTo
        let tickDataFrom

        if (currencyFrom === currencyTo) return {askRate: 1, bidRate: 1}
        if (currencyConversion === currencyTo) tickDataTo = tickDataSameSame
        if (currencyConversion === currencyFrom) tickDataFrom = tickDataSameSame

        if (!tickDataTo && hasConversionPair(`${currencyConversion}${currencyTo}`)) {
            tickDataTo = await findConversionPairRates(`${currencyConversion}${currencyTo}`)
        }
        if (!tickDataTo && hasConversionPair(`${currencyTo}${currencyConversion}`)) {
            const tickDataToLocal = await findConversionPairRates(`${currencyTo}${currencyConversion}`)
            if (tickDataToLocal) tickDataTo = {ask: 1 / tickDataToLocal.ask, bid: 1 / tickDataToLocal.bid}
        }
        if (!tickDataFrom && hasConversionPair(`${currencyConversion}${currencyFrom}`)) {
            const tickDataFromLocal = await findConversionPairRates(`${currencyConversion}${currencyFrom}`)
            if (tickDataFromLocal) tickDataFrom = {ask: 1 / tickDataFromLocal.ask, bid: 1 / tickDataFromLocal.bid}
        }
        if (!tickDataFrom && hasConversionPair(`${currencyFrom}${currencyConversion}`)) {
            tickDataFrom = await findConversionPairRates(`${currencyFrom}${currencyConversion}`)
        }
        if (!tickDataTo || !tickDataFrom) {
            return {askRate: 0, bidRate: 0}
        }
        return {
            askRate: tickDataTo.ask * tickDataFrom.ask,
            bidRate: tickDataTo.bid * tickDataFrom.bid
        }
    }

    async symbolInfo(symbol: string): Promise<InstrumentInfo> {
        await this.checkInitiationAccountManagerData()
        const symbolInfo = this.symbolsMap.get(symbol) as LibrarySymbolInfo
        const {library_custom_fields: symbolInfoExtra} = symbolInfo
        const {currency_profit: currencyProfit, contract_size} = symbolInfoExtra as any
        const {currency: currencyAccount} = this._accountManagerDataNative
        const {volume_min_lots, volume_limit_lots, volume_max_lots, volume_step_lots} = symbolInfoExtra as any

        const rate = await this.findCrossCurrencyRate({currencyFrom: currencyProfit, currencyTo: currencyAccount}, [
            this.findLastTickDataFromTicks.bind(this),
            this.findLastTickDataFromRequest.bind(this)
        ])

        const pipSize = +(Math.pow(10, -symbolInfoExtra!.digits!)).toFixed(symbolInfoExtra!.digits as any)
        let minMaxSizeLotsForOnePosition: number = (volume_limit_lots > 0 && volume_limit_lots <= volume_max_lots) ? volume_limit_lots : volume_max_lots

        return {
            qty: {
                min: volume_min_lots as number,
                max: minMaxSizeLotsForOnePosition,
                step: volume_step_lots as number,
                uiStep: volume_step_lots as number,
                default: volume_min_lots as number
            },
            units: "Lots",
            currency: currencyAccount,
            pipValue: pipSize * contract_size * rate.bidRate,
            pipSize,
            minTick: pipSize,
            description: symbolInfo.description
        }
    }

    public currentAccount(): AccountId {
        return this.contextAuth.loginAccountId as unknown as AccountId
    }

    async checkInitiationAccountManagerData(): Promise<void> {
        if (!this._accountManagerDataNative) {
            await this._fetchAccountManagerData()
        }
    }

    public async accountsMetainfo(): Promise<AccountMetainfo[]> {
        await this.checkInitiationAccountManagerData()
        return [{
            id: (this.contextAuth.loginAccountId || "0") as AccountId,
            name: `${this.contextAuth.loginAccountId}`,
            currency: this._accountManagerDataNative.currency
        }]
    }

    private _bottomContextMenuItems(activePageActions: ActionMetaInfo[]): ActionMetaInfo[] {
        const separator: MenuSeparator = {separator: true}
        const sellBuyButtonsVisibility = this._host.sellBuyButtonsVisibility()
        if (activePageActions.length) {
            activePageActions.push(separator)
        }
        return activePageActions.concat([
            {
                text: "Show Buy/Sell Buttons",
                action: () => {
                    if (sellBuyButtonsVisibility) {
                        sellBuyButtonsVisibility.setValue(!sellBuyButtonsVisibility.value())
                    }
                },
                checkable: true,
                checked: sellBuyButtonsVisibility !== null && sellBuyButtonsVisibility.value()
            },
            {
                text: "Trading Settings...",
                action: () => {
                    this._host.showTradingProperties()
                }
            }
        ])
    }

    _handleEquityUpdate = (value: number): void => {
        setTimeout(() => this._host.equityUpdate(value), 0)
    }

    private _updateOrder(order: Order): void {
        const hasOrderAlready = Boolean(this._orderById[order.id])
        this._orderById[order.id] = order
        if (!hasOrderAlready) {
            this._subscribeData(order.symbol, order.id, () => {
            })
        }
        this._host.orderUpdate({...order})
    }

    private _updateOrderWithBracket(
        entity: Order | Position,
        type: "Order" | "Position"
    ): void {
        const isPositiveNumber = (val?: number): boolean => {
            return typeof val === "number" && val > 0
        }

        const bracketBase: Partial<Order> = {
            symbol: entity.symbol,
            qty: entity.qty,
            parentId: entity.id,
            side: invertSide(entity.side),
            avgPrice: entity.avgPrice,
            time: entity.time,
            last: entity.last
        }

        let takeProfitOrderId = `${entity.id} TP`
        let stopLossOrderId = `${entity.id} SL`

        if (type === "Order") {
            takeProfitOrderId = `Pending ${takeProfitOrderId}`
            stopLossOrderId = `Pending ${stopLossOrderId}`
            bracketBase.parentType = ParentType.Order
            bracketBase.status = OrderStatus.Inactive
        } else {
            takeProfitOrderId = `Position ${takeProfitOrderId}`
            stopLossOrderId = `Position ${stopLossOrderId}`
            bracketBase.parentType = ParentType.Position
            bracketBase.status = OrderStatus.Working
        }

        const existingTP = this._orderById[takeProfitOrderId]
        const existingSL = this._orderById[stopLossOrderId]

        if (entity.qty === 0) {
            if (existingTP) {
                existingTP.status = OrderStatus.Canceled
                this._updateOrder(existingTP)
            }
            if (existingSL) {
                existingSL.status = OrderStatus.Canceled
                this._updateOrder(existingSL)
            }
            return
        }

        const isTPSet = isPositiveNumber(entity.takeProfit)
        if (isTPSet) {
            const takeProfitOrder: Order = {
                ...bracketBase,
                id: takeProfitOrderId,
                limitPrice: entity.takeProfit,
                takeProfit: entity.takeProfit,
                type: OrderType.Limit
            } as Order
            this._updateOrder(takeProfitOrder)
        } else if (existingTP && existingTP.parentId === entity.id) {
            existingTP.status = OrderStatus.Canceled
            this._updateOrder(existingTP)
        }

        const isSLSet = isPositiveNumber(entity.stopLoss)
        if (isSLSet) {
            const stopLossOrder: Order = {
                ...bracketBase,
                id: stopLossOrderId,
                stopPrice: entity.stopLoss,
                stopLoss: entity.stopLoss,
                type: OrderType.Stop
            } as Order
            this._updateOrder(stopLossOrder)
        } else if (existingSL && existingSL.parentId === entity.id) {
            existingSL.status = OrderStatus.Canceled
            this._updateOrder(existingSL)
        }

        function invertSide(side: Side): Side {
            return side === Side.Buy ? Side.Sell : Side.Buy
        }
    }

    private _updatePosition(position: Position): void {
        const existingPosition = this._positionById[position.id]

        if (existingPosition && !position.qty) {
            this._unsubscribeData(position.id)
            delete this._positionById[position.id]
            this._host.positionUpdate({...position})
            return
        }
        if (!existingPosition) {
            this._subscribeData(position.symbol, position.id, () => {
            })
        }
        this._positionById[position.id] = position
        this._host.positionUpdate({...position})
    }

    private _createExecution(position: Position): void {
        if (!position.qty) return
        const execution: Execution = {
            id: `${position.id} EXEC`,
            time: position.time,
            symbol: position.symbol,
            price: position.avgPrice,
            qty: position.qty,
            side: position.side
        }
        this._executions.push(execution)
        this._host.executionUpdate(execution)
    }

    private _tickDataHandlerOrders(possibleOrderId: string, data: DatafeedQuoteValues) {
        const {ask, bid} = data

        const order = this._orderById[possibleOrderId]
        if (!order) return

        const tradeType = order.side === Side.Buy ? 0 : 1
        const lastTickPrice = tradeType === 0 ? bid : ask

        if (lastTickPrice === order.last) return
        order.last = lastTickPrice
        this._host.orderPartialUpdate(order.id, {last: lastTickPrice})
    }

    private async _tickDataHandlerPositions(possiblePositionId: string, data: DatafeedQuoteValues) {
        const standard = [0, 2, 3, 4, 5, 32]
        const forex = [1, 33, 34]
        const {ask, bid, original_name: symbolName} = data

        const position = this._positionById[possiblePositionId]
        if (!position) return
        const {avgPrice, qty, last} = position

        const tradeType = position.side === Side.Buy ? 0 : 1
        const lastTickPrice = tradeType === 0 ? bid : ask

        if (lastTickPrice === last) return

        const symbolInfo = this.symbolsMap.get(symbolName!)
        if (!symbolInfo) return

        const {library_custom_fields} = symbolInfo
        const {calc_mode, contract_size, digits, currency_profit} = library_custom_fields as any

        let pl
        if (standard.includes(calc_mode)) {
            pl = calculationFunctions.profit[calc_mode]({
                closePrice: lastTickPrice,
                openPrice: avgPrice,
                contractSize: contract_size,
                lots: qty
            }, tradeType)
        } else if (forex.includes(calc_mode)) {
            pl = calculationFunctions.profit[calc_mode]({
                closePrice: lastTickPrice,
                openPrice: avgPrice,
                lots: qty,
                tickPrice: lastTickPrice,
                tickSize: Math.pow(10, -digits!)
            }, tradeType)
        }

        const {currency: currency_account} = this._accountManagerDataNative

        if (currency_account !== currency_profit) {
            const rate = await this.findCrossCurrencyRate({
                currencyFrom: currency_profit,
                currencyTo: currency_account
            }, [
                this.findLastTickDataFromTicks.bind(this)
            ])
            if (!rate.askRate || !rate.bidRate) return
            pl = pl * (tradeType === 0 ? rate.bidRate : rate.askRate)
        }

        position.pl = pl
        position.last = lastTickPrice
        this._host.positionUpdate({...position})
        this._recalculateAMData()
    }

    private async _tickDataHandlerEngine(quoteTickStateForTicket: Map<string, DatafeedQuoteValues>) {
        for (const [key] of quoteTickStateForTicket) {
            if (!this._orderById[key] && !this._positionById[key]) {
                quoteTickStateForTicket.delete(key)
                continue
            }

            const data = quoteTickStateForTicket.get(key)
            if (!data) return

            this._tickDataHandlerOrders(key, data)
            await this._tickDataHandlerPositions(key, data)
        }
    }

    private _subscribeData(symbol: string, id: string, callback: (data: DatafeedQuoteValues) => void): void {
        this._quotesProvider.subscribeQuotes(
            [],
            [symbol],
            (symbols: QuoteData[]) => {
                const [deltaData] = symbols
                if (deltaData?.s !== "ok") return
                callback(deltaData.v)
            },
            this.getDatafeedSubscriptionId(id)
        )
    }

    private _unsubscribeData(id: string): void {
        this._quotesProvider.unsubscribeQuotes(this.getDatafeedSubscriptionId(id))
    }

    getDatafeedSubscriptionId(id: string): string {
        return `Broker-${id}`
    }

    private _defineOrderTypeAndSide(mt5Type: number): { type: OrderType; side: Side } {
        return defineOrderTypeAndSide(mt5Type)
    }

    private _defineOrderStatus(mt5State: number): OrderStatus {
        switch (mt5State) {
            case 0: // STARTED
                return OrderStatus.Placing
            case 1: // PLACED
                return OrderStatus.Working
            case 2: // CANCELED
                return OrderStatus.Canceled
            case 3: // PARTIAL
                return OrderStatus.Working
            case 4: // FILLED
                return OrderStatus.Filled
            case 5: // REJECTED
                return OrderStatus.Rejected
            case 6: // EXPIRED
                return OrderStatus.Canceled
            case 7: // REQUEST_ADD
                return OrderStatus.Placing
            case 8: // REQUEST_MODIFY
                return OrderStatus.Working
            case 9: // REQUEST_CANCEL
                return OrderStatus.Placing
            default:
                return OrderStatus.Inactive
        }
    }

    private _defineTradeExecutionErrorType(mt5Type: number): string {
        return defineTradeExecutionErrorType(mt5Type)
    }

    private _defineFundOperationType(mt5Type: number): string {
        return defineFundOperationType(mt5Type)
    }

}




